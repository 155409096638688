.i-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.appointment {
    background-image: url(../../assets/appointment.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 1rem;
}

.appointment_desktop {
    background-image: url(../../assets/appointment-desktop.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 1rem;
}

.app_card {
    padding: 2rem 1.5rem;
    background: white;
}

.app_txt {
    color: white;
    text-align: left;
}