.footer_container {
    background: linear-gradient(273deg,#bedade, #eaf2f3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dott {
    border-bottom: 1px solid grey;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.info_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shape{
    position: absolute;
    top: -1px !important;
    rotate: 180deg;
    width: 100%;
    max-height: 35px;
}