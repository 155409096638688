@import url('https://fonts.cdnfonts.com/css/ontario');

body {
  font-family: var(--font-family);
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
}

.forDesktop {
  max-width: 800px !important;
  width: 100% !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.pointer{
  cursor: pointer !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: darkslategray;
  position: relative;
}

.relative {
  position: relative !important;
}

.flex {
  display: flex;
}

.a-c {
  align-items: center;
}

.j-s-b {
  justify-content: space-between;
}

.ontario {
  font-family: 'Ontario', sans-serif !important;
}

.fs-12 {
  font-size: 12px;
}

.fs-18 {
  font-size: 18px;
}

.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.fs-64 {
  font-size: 64px;
}

.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.p-menubar {
  padding: 0.75rem !important;
}

.w-33 {
  width: 33.3% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.ml-025 {
  margin-left: 0.25rem !important;
}

.mr-025 {
  margin-right: 0.25rem !important;
}

.ml-05 {
  margin-left: 0.5rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.mt-05 {
  margin-top: 0.5rem !important;
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

.mt-15 {
  margin-top: 1.5rem !important;
}

.mb-15 {
  margin-bottom: 1.5rem !important;
}

.mt-3 {
  margin-top: 3rem;
}

.plr-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.p-155 {
  padding: 1.55rem;
}

.p-2 {
  padding: 2rem;
}

.p-7 {
  padding: 7rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.label {
  left: 0.75rem;
  color: #6c757d;
  transition-duration: 0.2s;
  margin-bottom: 0.5rem;
}

.mw-500 {
  max-width: 500px !important;
}

.mw-700 {
  max-width: 700px !important;
}

.t-a-l {
  text-align: left !important;
}

.bck_trasparent {
  background: rgba(255,255,255, 0.8) !important;
}

.loader {
  z-index: 99999 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 auto !important;
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  top: 10% !important;
  bottom: 0 !important;
  width: 70px !important;
}

.p-progress-spinner-svg {
  z-index: 99999 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 auto !important;
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  top: 10% !important;
  bottom: 0 !important;
  width: 70px !important;
}