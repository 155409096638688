.nav-container {
    width: 100%;
    padding: 0;
    background: white;
    padding: 1rem;
    backdrop-filter: blur(5px);
    background-color: rgba(0,0,0,0);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999 !important;
}

.p-menubar {
    background: white !important;
    border: none !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none !important;
}