.desc_container {
    display: flex;
    align-items: center;
    background-image: url(../../assets/blob.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    min-height: 600px;
}

.desc_container_desk {
    display: flex;
    align-items: center;
    background-image: url(../../assets/blob.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.profile {
    width: 30vw !important;
}

.desc_txt {
    color: white;
}

.p-mobile {
    padding: 2.5rem 1rem;
}

.chisono_container {
    padding-bottom: 1rem;
    padding-top: 1rem;
    box-sizing: content-box;
}

.formazione_container {
    padding-top: 1rem;
    background: #eaf2f3;
    padding-bottom: 1rem;
    box-sizing: content-box;
}

.esperienze_container {
    padding-top: 1rem;
    background: #bedade;
    padding-bottom: 1rem;
    box-sizing: content-box;
}

.mh-355 {
    min-height: 355px;
}

.descr {
    display: -webkit-box;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
}
