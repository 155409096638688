.first_container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 90vh;
    min-width: 95;
    display: flex;
    align-items: center;
    justify-content: start;
}

.home_desk {
    background-image: url(../../assets/home-page.jpg);
}

.home_mob {
    background-image: url(../../assets/home-page-mobile.jpg);
}

.second_container {
    display: flex;
    align-items: center;
    justify-content: end;
}

.section {
    padding: 30px 60px 30px 40px;
    vertical-align: middle;
    position: relative;
}

.b_o {
    background-color: white;
}

.b_two {
    background-color: #eaf2f3;
}

.b_t {
    background-color: #bedade;
}

.column {
    flex-direction: column !important;
}

.arrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
}

.mh-350 {
    min-height: 350px;
}

.mh-265 {
    min-height: 265px;
}