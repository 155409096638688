.app_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.p-card {
    box-shadow: 0 7px 7px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.15)!important;
}

.p-card-content {
    padding: 0 !important;
}

.card_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.save_btn {
    position: sticky;
    bottom: 1%;
    width: 100%;
    background: white;
}