.content {
    text-align: left;
    padding: 2rem;
}

.app {
    background-image: url(../../assets/solidarieta.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white !important;
    max-width: 500px;
}

.centerize {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}